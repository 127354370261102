.progress.is-short {
    max-width: 168px;
}

.progress.is-xsmall {
    height: 0.5em;
    max-width: 168px;
}

.is-word-break-all {
    word-break: break-all;
}

.is-ellipsized {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}